import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";

export async function getPortalLink() {
  const createPortalLink = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );

  const { data }: any = await createPortalLink({
    returnUrl: window.location.origin + "/account",
    locale: "auto", // Optional, defaults to "auto"
    // configuration: "bpc_1LLe0MHI7X0IZreyh4ppswj9", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
  });

  if (data.url) {
    window.location.assign(data.url);
  }
}
