import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase.config";
import { useDispatch } from "react-redux";
import { login } from "../redux/authSlice";
import { doc, setDoc } from "firebase/firestore";

export default function FinishLogin() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn") || "";
      if (!email) {
        email = window.prompt("Please confirm your email") || "";
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((userCredential) => {
          window.localStorage.removeItem("emailForSignIn");

          setDoc(
            doc(db, "users", userCredential.user.uid),
            {
              displayName: userCredential.user.email,
              uid: userCredential.user.uid,
              email: userCredential.user.email,
            },
            { merge: true }
          );

          dispatch(
            login({
              uid: userCredential.user.uid,
              email: userCredential.user.email,
              displayName: userCredential.user.email,
            })
          );
          navigate("/tools");
        })
        .catch((error) => {
          console.log("ERROR", error);
          setErrorMessage(error.message.replace("Firebase:", ""));
        });
    } else {
      setErrorMessage("Error: Sign in link is not valid");
    }
  }, [dispatch, navigate]);

  return (
    <div>
      {errorMessage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "30px auto",
            gap: "15px",
          }}
        >
          <p>{errorMessage}</p>
          <button onClick={() => navigate("/login")}>Try Again</button>
        </div>
      ) : (
        <p>Signing in...</p>
      )}
    </div>
  );
}
