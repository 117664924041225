import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase.config";

export default function useSubscription() {
  const { currentUser } = auth;
  const uid = currentUser?.uid;
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);

  useEffect(() => {
    async function getData() {
      if (uid) {
        const c = collection(db, "customers", uid, "subscriptions");
        const q = query(c, where("status", "==", "active"));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((sub) => {
          const s = sub.data();

          if (s?.role === "premium") {
            setStart(s?.current_period_start?.seconds);
            setEnd(s?.current_period_end?.seconds);
          }
        });
      }
    }
    getData();
  }, [uid]);

  return { start, end };
}
